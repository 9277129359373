import React, { createContext, useContext } from 'react';

function isFlagEnabled(flagName) {
  return process.env[`REACT_APP_${flagName}`] === 'true';
}

export const FeatureFlagsContext = createContext();

export function FeatureFlagsProvider({ children }) {
  return (
    <FeatureFlagsContext.Provider
      value={{
        isBillingEnabled: isFlagEnabled('BILLING_RELEASED'),
        isRoutesIndexEnabled: isFlagEnabled('ROUTES_INDEX_RELEASED'),
        isShipmentEventIssuesEnabled: isFlagEnabled(
          'SHIPMENT_EVENT_ISSUES_ACTIVE'
        ),
        isNotificationSettingsEnabled: isFlagEnabled(
          'NOTIFICATION_SETTINGS_ACTIVE'
        ),
      }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export function useFeatureFlags() {
  const context = useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagsProvider'
    );
  }

  return context;
}
