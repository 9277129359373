import React from 'react';
import { ShipmentEventHistory } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

import { useFeatureFlags } from 'context/featureFlags';
import { Lang } from 'core/utils/constants';
import { formatShortDate } from 'core/utils/date-fns';

export function ShipmentEvents(props) {
  const { currentShipmentEvent, events, isLoading, isShipmentCompleted } =
    props;

  const {
    i18n: { language },
    t,
  } = useTranslation();

  const { isShipmentEventIssuesEnabled } = useFeatureFlags();

  if (isLoading) {
    return null;
  }

  const completedEvents = events
    .filter(({ actualDatetime }) => Boolean(actualDatetime))
    .reverse();

  const formattedCompletedEvents = completedEvents.map(
    ({ id, type, actualDatetime, shipmentEventIssues }) => ({
      details: `${t('confirmed')} • ${formatShortDate(actualDatetime)}`,
      icon: { color: 'white', name: 'check' },
      id,
      issues: formatEventIssues({
        enabled: isShipmentEventIssuesEnabled,
        dateFormatter: formatShortDate,
        language,
        t,
        issues: shipmentEventIssues,
      }),
      name: t(`shipment-event-type-${type}`),
      type: 'Occurred',
    })
  );

  const formattedUpcomingEvent = {
    details: t('shipment-event-upcoming-date'),
    id: currentShipmentEvent.id,
    issues: formatEventIssues({
      enabled: isShipmentEventIssuesEnabled,
      dateFormatter: formatShortDate,
      language,
      t,
      issues: currentShipmentEvent.shipmentEventIssues,
    }),
    name: t(`shipment-event-type-${currentShipmentEvent.type}`),
    type: 'Upcoming',
  };

  return (
    <section
      data-testid="shipment-events"
      className="col-span-1 flex flex-col gap-y-8 rounded bg-nuvo-white p-8 lg:col-span-7 lg:col-start-1">
      {!isShipmentCompleted && (
        <ShipmentEventHistory
          dataTestId="shipment-event-upcoming"
          events={[formattedUpcomingEvent]}
          hideTimeline
          headingProps={{ variant: 'h5' }}
          showMoreText={t('shipment-details-show-more')}
          showLessText={t('shipment-details-show-less')}
          // Let the parent handle the padding
          styles={{ root: { padding: '0px !important' } }}
          title={t('shipment-details-events-upcoming-title')}
        />
      )}

      {completedEvents.length > 0 && (
        <ShipmentEventHistory
          dataTestId="shipment-event-history"
          events={formattedCompletedEvents}
          headingProps={{ variant: 'h5' }}
          outstandingEvent={
            isShipmentCompleted
              ? {
                  date: formatShortDate(
                    completedEvents[0].actualDatetime,
                    language
                  ),
                  description: t('shipment-event-delivered-description'),
                  icon: { color: 'white', name: 'check' },
                  name: t('shipment-event-type-delivered'),
                  type: 'Occurred',
                }
              : null
          }
          showMoreText={t('shipment-event-history-show-more')}
          showLessText={t('shipment-event-history-show-less')}
          // Let the parent handle the padding
          styles={{ root: { padding: '0px !important' } }}
          subtitle={t('shipment-details-events-completed-subtitle')}
          title={t('shipment-details-events-completed-title')}
        />
      )}
    </section>
  );
}

function formatEventIssues({ enabled, issues, language, t, dateFormatter }) {
  /**
   * @TODO Once shipmentEventIssues feature is enabled, make sure to include
   * related fields to the GraphQL query.
   */
  if (!enabled) {
    return [];
  }

  // TODO: Only ask for issues that are published or filter these issues on the backend instead.
  return issues
    ?.filter(issue => {
      const { noteEnglish, noteSpanish, resolver } = issue;
      return Boolean(noteEnglish) && Boolean(noteSpanish) && Boolean(resolver);
    })
    .map(issue => {
      const { createdAt, id, noteEnglish, noteSpanish, reason, updatedAt } =
        issue;

      const description = language === Lang.ES ? noteSpanish : noteEnglish;
      const editedLabel =
        createdAt !== updatedAt ? `(${t('exception-edited')})` : '';

      return {
        id,
        description,
        details: `${t('exception')} • ${dateFormatter(
          updatedAt,
          language
        )} ${editedLabel}`,
        icon: {
          color: 'steel',
          name: 'errorOutline',
          size: 'small',
        },
        name: t(`exception-${reason.replace(/_/g, '-')}`),
        type: 'Exception',
      };
    });
}
