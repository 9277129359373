import React, { useEffect, useMemo } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AuthenticatedHeader, Grid, Icon } from '@nuvocargo/nuvo-styleguide';
import { toast } from 'react-toastify';

import { useUser } from 'context/user';
import { signOut } from 'core/api/user';
import { useCompanyBalance } from 'screens/shipments/hooks';
import { app } from 'routes/paths';
import { useFeatureFlags } from 'context/featureFlags';

import ImpersonationBanner from './ImpersonationBanner';
import OverdueBanner from './OverdueBanner';
import FinanceHoldBanner from './FinanceHoldBanner';

export default function Header({
  children,
  paddingTop = '32px',
  marginTop = '0',
  ignoreGrid = false,
  isTableLayout = false,
  ignoreBanner = false,
}) {
  const { t } = useTranslation();
  const { currentUser } = useUser();
  const queryClient = useQueryClient();

  const history = useHistory();
  const isImpersonating = !!currentUser.impersonatedFullName;
  const BALANCE_OVERDUE_BANNER = 'balance-overdue-banner';
  const FINANCE_HOLD_BANNER = 'finance-hold-banner';
  const IMPERSONATION_BANNER = 'impersonation-banner';

  const isShipmentsTab = useMemo(
    () =>
      history.location.pathname === app.shipments.root ||
      history.location.pathname === `${app.shipments.root}/`,
    [history.location.pathname]
  );

  const { mutate: signOutMutation } = useMutation(signOut, {
    onSuccess: () => {
      toast.dismiss();
      queryClient.invalidateQueries('check-auth');
    },
  });

  useCompanyBalance({
    enabled: isShipmentsTab,
    onSuccess: data => {
      if (data.financeHold && data.isCompanyBalanceOverdue && isShipmentsTab) {
        toast.error(<FinanceHoldBanner />, {
          autoClose: false,
          toastId: FINANCE_HOLD_BANNER,
          icon: <Icon name="errorOutline" color="inherit" size="medium" />,
          closeOnClick: false,
          draggable: false,
          containerId: 'banner',
        });
      } else if (data.isCompanyBalanceOverdue && isShipmentsTab) {
        toast.warning(<OverdueBanner overdueData={data.overdueData} />, {
          autoClose: false,
          toastId: BALANCE_OVERDUE_BANNER,
          icon: <Icon name="errorOutline" color="inherit" size="medium" />,
          closeOnClick: false,
          draggable: false,
          containerId: 'banner',
        });
      }
    },
  });

  useEffect(() => {
    // Show ImpersonationBanner on every isShipmentsTab change
    if (isImpersonating) {
      toast.success(<ImpersonationBanner currentUser={currentUser} />, {
        autoClose: false,
        toastId: IMPERSONATION_BANNER,
        icon: <Icon name="removeRedEye" color="inherit" size="medium" />,
        closeOnClick: false,
        draggable: false,
        containerId: 'banner',
      });
    }

    // Dismiss OverdueBanner or FinanceHoldBanner when not on Shipments tab
    if (!isShipmentsTab) {
      toast.dismiss(BALANCE_OVERDUE_BANNER);
      toast.dismiss(FINANCE_HOLD_BANNER);
    }
  }, [currentUser, isImpersonating, isShipmentsTab]);

  return (
    <>
      <AuthenticatedHeader
        ctaProps={{
          text: t('new-shipment'),
          props: {
            to: '/shipments/new',
            isLink: true,
          },
          component: Link,
        }}
        links={
          <>
            <NavLink to="/shipments" exact>
              {t('shipments-header')}
            </NavLink>
            {/** @TODO Check if those env vars are still needed */}
            {process.env.REACT_APP_ROUTES_INDEX_RELEASED === 'true' && (
              <NavLink to="/routes">{t('routes')}</NavLink>
            )}
            <NavLink to="/insights">{t('insights')}</NavLink>
            {process.env.REACT_APP_BILLING_RELEASED === 'true' && (
              <NavLink to="/billing">{t('billing')}</NavLink>
            )}
          </>
        }
        logoProps={{
          component: Link,
          props: {
            to: '/',
          },
        }}
        menuProps={{
          text: `${t('hello')}, ${
            isImpersonating
              ? currentUser?.impersonatedFullName.split(' ')[0]
              : currentUser?.firstName
          }`,
          options: [
            {
              text: t('log-out'),
              onClick: signOutMutation,
            },
            {
              text: t('settings'),
              link: {
                component: NavLink,
                props: {
                  to: '/settings',
                },
              },
            },
          ],
        }}
        styles={{
          desktop: {
            position: 'sticky',
          },
          mobile: {
            marginBottom: '16px',
          },
        }}
        ignoreBanner={ignoreBanner}
      />

      {ignoreGrid ? (
        children
      ) : (
        <Grid styles={gridStyle(paddingTop, marginTop, isTableLayout)}>
          {children}
        </Grid>
      )}
    </>
  );
}

const gridStyle = (paddingTop, marginTop, isTableLayout = false) => {
  const tableStyle = {
    width: '100%',
    maxWidth: '100%',
    margin: 0,
  };

  const style = {
    root: {
      paddingTop,
      marginTop,
      ...(isTableLayout ? tableStyle : {}),
      [`@media only screen and (max-width: 968px)`]: {
        paddingTop: '16px',
      },
    },
  };
  return style;
};
