import React, { useEffect } from 'react';
import { Text } from '@nuvocargo/nuvo-styleguide';
import { Dropdown, Textarea } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

import {
  buildAppointmentTypeOptions,
  buildLoadingDurationOptions,
  buildReceivingDaysOptions,
  buildSchemaOptions,
  PROCEDURES_LOADING_DURATION,
  PROCEDURES_LOADING_SCHEMA_MAP,
} from 'core/utils/route-facility';
import { TimeSelector } from 'components/generic/form/formik/TimeSelector';

const Procedures = ({ facilityType }) => {
  const { t } = useTranslation();
  const [loadingSchemeField = {}] = useField({ name: 'loadingScheme' });
  const [_, __, loadingDurationHelper] = useField({
    name: 'loadingDuration',
  });

  const schemaOptions = buildSchemaOptions({ t });
  const appointmentTypeOptions = buildAppointmentTypeOptions({ t });
  const receivingDaysOptions = buildReceivingDaysOptions({ t });
  const loadingDurationOptions = buildLoadingDurationOptions({
    loadingSchema: loadingSchemeField.value.value,
    t,
  });

  useEffect(() => {
    let option;

    if (loadingSchemeField.value.value === PROCEDURES_LOADING_SCHEMA_MAP.LIVE) {
      option = loadingDurationOptions.find(
        option =>
          option.value === PROCEDURES_LOADING_DURATION.LIVE.LESS_THAN_TWO_HOURS
      );
    }

    if (loadingSchemeField.value.value === PROCEDURES_LOADING_SCHEMA_MAP.DROP) {
      option = loadingDurationOptions.find(
        option =>
          option.value === PROCEDURES_LOADING_DURATION.DROP.TWENTY_FOUR_HOURS
      );
    }

    loadingDurationHelper.setValue({
      ...option,
    });
  }, [loadingSchemeField.value.value]);

  return (
    <div className="flex flex-col gap-6">
      <div className="border-b border-solid border-nuvo-gray-dark/10 pb-1">
        <Text fontWeight="bold" color="green" size="large">
          {t(`facility-form-${facilityType}-procedures-title`)}
        </Text>
      </div>

      <div className="flex w-full gap-8">
        <div className="flex-1" data-testid="loading-schema">
          <Dropdown
            label={t(`facility-form-${facilityType}-loading-schema-label`)}
            options={schemaOptions}
            name="loadingScheme"
          />
        </div>
        <div className="flex-1" data-testid="appointment-type">
          <Dropdown
            label={t(`facility-form-${facilityType}-appointment-type-label`)}
            name="appointmentType"
            placeholder={t(
              `facility-form-${facilityType}-appointment-type-placeholder`
            )}
            options={appointmentTypeOptions}
          />
        </div>
      </div>

      <div className="flex w-full gap-8">
        <div className="flex-1">
          <TimeSelector
            label={t(`facility-form-${facilityType}-loading-starts-label`)}
            placeholder={t(
              `facility-form-${facilityType}-loading-starts-placeholder`
            )}
            buttonPlaceholder={t(
              `facility-form-loading-${facilityType}-starts-button-placeholder`
            )}
            name="startTime"
          />
        </div>
        <div className="flex-1">
          <TimeSelector
            label={t(`facility-form-${facilityType}-loading-ends-label`)}
            placeholder={t(
              `facility-form-${facilityType}-loading-ends-placeholder`
            )}
            buttonPlaceholder={t(
              `facility-form-${facilityType}-loading-ends-button-placeholder`
            )}
            name="endTime"
          />
        </div>
      </div>
      {/*Receiving days + loading time required*/}
      <div className="flex w-full gap-8">
        <div className="flex-1" data-testid="receiving-days">
          <Dropdown
            label={t(`facility-form-${facilityType}-receiving-days-label`)}
            placeholder={t(
              `facility-form-${facilityType}-receiving-days-placeholder`
            )}
            name="receivingDays"
            options={receivingDaysOptions}
          />
        </div>
        <div className="flex-1" data-testid="loading-duration">
          <Dropdown
            label={t(`facility-form-${facilityType}-loading-duration-label`)}
            placeholder={t(
              `facility-form-${facilityType}-loading-duration-placeholder`
            )}
            name="loadingDuration"
            options={loadingDurationOptions}
          />
        </div>
      </div>
      <Textarea
        name="facilityInstructions"
        placeholder={t(
          `facility-form-${facilityType}-instructions-label-placeholder`
        )}
        label={t(`facility-form-${facilityType}-instructions-label-label`)}
      />
    </div>
  );
};

export default Procedures;
