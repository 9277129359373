const { gql } = require('graphql-request');

export const attachmentFieldsFragment = gql`
  fragment attachmentFields on Attachment {
    fileName
    url
    createdAt
  }
`;

export const coordinatesFragment = gql`
  fragment coordinates on Place {
    id
    latitude
    longitude
  }
`;

export const placeFragment = gql`
  fragment place on Place {
    ...coordinates
    name
    addressLine1
    addressLine2
    city {
      name
    }
    zipCode {
      parents {
        city
        country
      }
    }
  }

  ${coordinatesFragment}
`;

export const shipmentEventFragment = gql`
  fragment shipmentEvent on ShipmentEvent {
    actualDatetime
    estimatedDatetime
    id
    type
    airtableId
    notified
    notifyCustomer
    wasEstimatedDatetimeUpdated
    shipmentEventIssues {
      id
      reason
      noteEnglish
      noteSpanish
      createdAt
      updatedAt
      resolver {
        id
        name
        email
      }
    }
  }
`;

export const SHIPMENT_QUERY = gql`
  query customerui_getShipment($companyId: String, $shipmentId: ID) {
    shipments(first: 1, companyId: $companyId, id: $shipmentId) {
      edges {
        node {
          id
          folioNumber
          state
          customerReference
          origin {
            ...place
          }
          destination {
            ...place
          }
          currentShipmentEvent {
            ...shipmentEvent
          }
          shipmentEvents(first: 50) {
            totalCount
            nodes {
              ...shipmentEvent
            }
          }
          route {
            id
          }
          company {
            name
          }
          priceInCents
          currency
          legs {
            id
            carrier {
              id
              name
            }
            driverName
            trailerNumber
            trailerPlateNumber
            trailerPlateState
            truckNumber
            truckPlateNumber
            truckPlateState
            crossBorder
            origin {
              ...coordinates
              zipCode {
                parents {
                  city
                }
              }
            }
            destination {
              ...coordinates
              zipCode {
                parents {
                  city
                }
              }
            }
            trailerNumber
            lastLeg
            podSent
            pod {
              ...attachmentFields
            }
            receivableLineItems {
              edges {
                node {
                  id
                  invoiceXml {
                    ...attachmentFields
                  }
                  invoice {
                    ...attachmentFields
                  }
                }
              }
            }
            pita {
              ...attachmentFields
            }
            doda {
              ...attachmentFields
            }
            manifest {
              ...attachmentFields
            }
            cartaPorte {
              xmlDocument {
                ...attachmentFields
              }
              fromClient {
                ...attachmentFields
              }
              fromCarrier {
                ...attachmentFields
              }
            }
          }

          attachments {
            id
            category
            name
            createdAt
            document {
              ...attachmentFields
            }
          }
          documents {
            ...attachmentFields
          }
          customerInvoice {
            ...attachmentFields
          }
        }
      }
    }
  }

  ${placeFragment}
  ${shipmentEventFragment}
  ${attachmentFieldsFragment}
`;
